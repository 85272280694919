<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit Product Festives">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="NameN" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeT"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidFrom"
              v-model="ValidFr"
              :min="ValidFr"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValidFrom')"
              >{{ errors.first("ValidFrom") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidTo"
              v-model="ValidT"
              :min="ValidFr"
            />
            <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
              errors.first("ValidTo")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <div class="vx-col sm:w-2/3 w-full">
              <input
                type="radio"
                v-model="selectedOption"
                value="days"
                @click="clearDate()"
              />TOP
            </div>
            <div class="vx-col sm:w-2/3 w-full">
              <input
                type="radio"
                v-model="selectedOption"
                value="Date"
                @click="clearDay()"
              />Date
            </div>
          </div>
          <div v-if="selectedOption === 'days'" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTOP"
              :options="optionTOP"
              :multiple="false"
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeTOP"
              :custom-label="customLableTOP"
            />
          </div>
          <div v-if="selectedOption === 'Date'" class="vx-col sm:w-2/3 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="Date"
              v-model="DateD"
            />
            <span class="text-danger text-sm" v-show="errors.has('Date')">{{
              errors.first("Date")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span> Distribution Channel </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDistributionChannel"
              :options="optionDistributionChannel"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeCC"
              :custom-label="customLableDistributionChannel"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 1 </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup1"
              :options="optionCustomerGroup1"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeCG1"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 2</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup2"
              :options="optionCustomerGroup2"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeCG2"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Group 3</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup3"
              :options="optionCustomerGroup3"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChangeCG3"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="ID"
              @search-change="handlerSearchChange"
              :custom-label="customLableCustomer"
            />
          </div>
          <div class="vx-col sm:w-1/6 w-full text-right">
            <vs-button
              size="medium"
              class="mr-2 mb-1 bg-success"
              icon-pack="feather"
              icon="icon-search"
              @click="handleFindCustomer()"
              >refresh</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 70%">
          <div class="vx-col sm:w-1/2 w-full">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table :data="cartProducts" stripe style="width: 100%">
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Quantity</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center"
                    >{{ dt.Name }} ({{ dt.Unit }})</vs-td
                  >
                  <vs-td style="align-item-center">
                    <vs-input type="number" v-model.number="dt.qty" :min="1" />
                  </vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm"
              >Save</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <!-- <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            > -->
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="unit">Unit</vs-th>
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].sku_code">{{
                data.records[indextr].sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item_name">{{
                data.records[indextr].item_name
              }}</vs-td>
              <vs-td :data="data.records[indextr].unit_name">{{
                data.records[indextr].unit_name
              }}</vs-td>
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  async mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    await this.getTerritory();
    this.ProductItem = this.$route.params.id;
    await this.getCustomerCategory();
    await this.getCustomerGroup1();
    await this.getCustomerGroup2();
    await this.getCustomerGroup3();
    await this.getProductItems(this.ProductItem);
    await this.getCustomer();
    await this.getPaymenTemp();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: {},
    optionTOP: [],
    selectedTOP: {},
    optionDistributionChannel: [],
    selectedDistributionChannel: [],
    optionCustomerGroup1: [],
    selectedCustomerGroup1: [],
    optionCustomerGroup2: [],
    selectedCustomerGroup2: [],
    optionCustomerGroup3: [],
    selectedCustomerGroup3: [],
    optionCustomer: [],
    selectedCustomer: [],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    ValidFr: "",
    ValidT: "",
    DateD: "",
    DayD: 0,
    NameN: "",
    companyID: 0,
    ProductItem: [],
    ID: 0,
    cartProducts: [],
    itemUnitID: [],
    selectedOption: "",
    status: true,
    territory_id: 0,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-festives",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.Input = this.resetReservation;

      this.cartProducts = [];

      this.itemsUom = [];
      this.itemsQuantity = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    addItemLine(item) {
      // console.log("ini itemLine>>>",);
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.id == val;
      });
      console.log(check);

      if (check.length == 0) {
        this.cartProducts.push({
          itemId: item.id,
          skuCode: item.sku_code,
          Name: item.item_name,
          Unit: item.unit_name,
        });
        this.itemUnitID.push(item.id);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        this.cartProducts.splice(index, 1);
        this.itemUnitID.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      // this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/table", {
          params: {
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;

            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.CompanyID = company_id;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      const id = this.$route.params.id;
      if (this.cartProducts.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      let territoryIDs = [];
      territoryIDs.push(this.selectedTerritory.ID);
      // this.selectedTerritory.forEach((element) => {
      //   territoryIDs.push(element.ID);
      // });
      let customerCategoryIDs = [];
      // customerCategoryIDs.push(this.selectedDistributionChannel.ID);
      this.selectedDistributionChannel.forEach((element) => {
        customerCategoryIDs.push(element.ID);
      });
      let CustomerGroupIDs1 = [];
      // CustomerGroupIDs1.push(this.selectedCustomerGroup1.ID);
      this.selectedCustomerGroup1.forEach((element) => {
        CustomerGroupIDs1.push(element.ID);
      });
      let CustomerGroupIDs2 = [];
      // CustomerGroupIDs2.push(this.selectedCustomerGroup2.ID);
      this.selectedCustomerGroup2.forEach((element) => {
        CustomerGroupIDs2.push(element.ID);
      });
      let CustomerGroupIDs3 = [];
      // CustomerGroupIDs3.push(this.selectedCustomerGroup3.ID);
      this.selectedCustomerGroup3.forEach((element) => {
        CustomerGroupIDs3.push(element.ID);
      });
      let CustomerIDs = [];
      this.selectedCustomer.forEach((element) => {
        CustomerIDs.push(element.ID);
      });

      this.$vs.loading();
      this.$http
        .put("/api/v1/master/productFestives/" + id + "/update", {
          name: this.NameN,
          date: this.DateD,
          valid_from: this.ValidFr,
          valid_to: this.ValidT,
          payment_term_id: this.selectedTOP.ID,
          territory_id: territoryIDs,
          customer_category_id: customerCategoryIDs,
          customer_group_id_1: CustomerGroupIDs1,
          customer_group_id_2: CustomerGroupIDs2,
          customer_group_id_3: CustomerGroupIDs3,
          customer_id: CustomerIDs,
          company_id: this.companyID,
          item_unit: this.cartProducts,
          ID: parseInt(this.$route.params.id),
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been Updated",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getPaymenTemp() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/paymenTerm", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            search: this.searchTop,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              // this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTOP.push(resp.data.records[index]);
              }
              // this.optionTOP = resp.data.records;
              if (this.optionTOP.length > 0) {
                // this.selectedTOP = this.optionTOP[0];
              } else {
                this.optionTOP = [];
                this.selectedTOP = {};
              }
            } else {
              this.optionTOP = [];
              this.selectedTOP = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/territories", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
            search: this.searchT,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              // this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                // this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-category", {
          params: {
            length: 999,
            sort: "name",
            order: "asc",
            search: this.searchCC,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionDistributionChannel = [
                { name: "ALL", classification: "", ID: 0 },
              ];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionDistributionChannel.push(resp.data.records[index]);
              }
              // this.optionDistributionChannel = resp.data.records;
              if (this.optionDistributionChannel.length > 0) {
                // this.selectedDistributionChannel =
                //   this.optionDistributionChannel[0];
              } else {
                console.log("BBBBBB");
                this.optionDistributionChannel = [];
                this.selectedDistributionChannel = {
                  code: "",
                  name: "ALL",
                  ID: 0,
                };
              }
            } else {
              this.optionDistributionChannel = [];
              this.selectedDistributionChannel = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup3() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 3,
            search: this.searchCG3,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup3 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup3.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup3 = resp.data.records;
              if (this.optionCustomerGroup3.length > 0) {
                // this.selectedCustomerGroup3 = this.optionCustomerGroup3[0];
              } else {
                this.optionCustomerGroup3 = [];
                this.selectedCustomerGroup3 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup3 = [];
              this.selectedCustomerGroup3 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 2,
            search: this.searchCG2,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup2 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup2.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup2 = resp.data.records;
              if (this.optionCustomerGroup2.length > 0) {
                // this.selectedCustomerGroup2 = this.optionCustomerGroup2[0];
              } else {
                this.optionCustomerGroup2 = [];
                this.selectedCustomerGroup2 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup2 = [];
              this.selectedCustomerGroup2 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer-group", {
          params: {
            length: 9999,
            order: "asc",
            sort: "name",
            level: 1,
            search: this.searchCG1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup1 = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup1.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup1 = resp.data.records;
              if (this.optionCustomerGroup1.length > 0) {
                // this.selectedCustomerGroup1 = this.optionCustomerGroup1[0];
              } else {
                this.optionCustomerGroup1 = [];
                this.selectedCustomerGroup1 = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomerGroup1 = [];
              this.selectedCustomerGroup1 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomer() {
      let DistributionChannelIDs = [];
      this.selectedDistributionChannel.forEach((element) => {
        if (element.ID != 0) {
          DistributionChannelIDs.push(element.ID);
        }
      });

      let CustomerGr1IDs = [];
      this.selectedCustomerGroup1.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr1IDs.push(element.ID);
        }
      });

      let CustomerGr2IDs = [];
      this.selectedCustomerGroup2.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr2IDs.push(element.ID);
        }
      });

      let CustomerGr3IDs = [];
      this.selectedCustomerGroup3.forEach((element) => {
        if (element.ID != 0) {
          CustomerGr3IDs.push(element.ID);
        }
      });

      let params = {
        length: 999,
        search: this.searchCust,
        customer_category_id: DistributionChannelIDs,
        customer_group_id_1: CustomerGr1IDs,
        customer_group_id_2: CustomerGr2IDs,
        customer_group_id_3: CustomerGr3IDs,
      };
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/customer", {
          params: {
            length: 10,
            order: "asc",
            sort: "name",
            search: this.searchCust,
            customer_category_id: DistributionChannelIDs,
            customer_group_id_1: CustomerGr1IDs,
            customer_group_id_2: CustomerGr2IDs,
            customer_group_id_3: CustomerGr3IDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = [{ code: "", name: "ALL", ID: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomer.push(resp.data.records[index]);
              }
              // this.optionCustomer = resp.data.records;
              if (this.optionCustomer.length > 0) {
                // this.selectedCustomer = this.optionCustomer[0];
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = { code: "", name: "ALL", ID: 0 };
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableTOP({ name }) {
      return `${name}`;
    },
    customLableDistributionChannel({ name, classification }) {
      return `${name} ${classification}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup1({ code, name }) {
      return `${code} ${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    getProductItems(id) {
      this.$http
        .get("/api/v1/master/productFestives/" + id + "/edit")
        .then((resp) => {
          if (resp.code == 200) {
            this.items = resp.data.records;
            this.companyID = this.$userLogin;
            // this.territory_name = resp.data.territory.name;
            // this.territory_id = resp.data.territory[0].ID;
            this.NameN = resp.data.data_product_festives.name;

            this.status = resp.data.data_product_festives.Status
              ? resp.data.data_product_festives.Status == "Active"
                ? true
                : false
              : false;
            this.ValidFr = moment(
              resp.data.data_product_festives.ValidFrom
            ).format("YYYY-MM-DD");
            this.ValidT = moment(
              resp.data.data_product_festives.ValidTo
            ).format("YYYY-MM-DD");
            this.DateD = moment(resp.data.data_product_festives.Date).format(
              "YYYY-MM-DD"
            );
            if (resp.data.data_product_festives.PaymentTermID != 0) {
              this.selectedOption = "days";
              this.selectedTOP = resp.data.payment_term[0]
                ? resp.data.payment_term[0]
                : {};
            } else {
              this.selectedOption = "Date";
            }
            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.cartProducts.push({
                itemId: resp.data.Data_Item[index].ID,
                skuCode: resp.data.Data_Item[index].Item.SkuCode,
                Name: resp.data.Data_Item[index].Item.Name,
                Unit: resp.data.Data_Item[index].Unit.Name,
                qty: resp.data.records[index].MinQty,
              });
            }
            console.log(">>>>>>>", this.selectedOption);
            this.selectedTerritory = resp.data.territory
              ? resp.data.territory.length > 0
                ? resp.data.territory
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];
            this.selectedCustomerGroup1 = resp.data.customer_group1
              ? resp.data.customer_group1.length > 0
                ? resp.data.customer_group1
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];
            this.selectedCustomerGroup2 = resp.data.customer_group2
              ? resp.data.customer_group2.length > 0
                ? resp.data.customer_group2
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];
            this.selectedCustomerGroup3 = resp.data.customer_group3
              ? resp.data.customer_group3.length > 0
                ? resp.data.customer_group3
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];

            this.selectedDistributionChannel = resp.data.customer_category
              ? resp.data.customer_category.length > 0
                ? resp.data.customer_category
                : [{ ID: 0, name: "ALL", classification: "" }]
              : [{ ID: 0, name: "ALL", classification: "" }];

            this.selectedCustomer = resp.data.customer
              ? resp.data.customer.length > 0
                ? resp.data.customer
                : [{ ID: 0, name: "ALL", code: "" }]
              : [{ ID: 0, name: "ALL", code: "" }];
          }
        });
    },
    clearDay() {
      this.selectedTOP = {};
    },
    clearDate() {
      this.DateD = "";
    },
    handlerSearchChange(search) {
      this.optionCustomer = [];
      this.searchCust = search;
      this.getCustomer();
    },
    handlerSearchChangeCG3(searching) {
      this.optionCustomerGroup3 = [];
      this.searchCG3 = searching;
      this.getCustomerGroup3();
    },
    handlerSearchChangeCG2(searching) {
      this.optionCustomerGroup2 = [];
      this.searchCG2 = searching;
      this.getCustomerGroup2();
    },
    handlerSearchChangeCG1(searching) {
      this.optionCustomerGroup1 = [];
      this.searchCG1 = searching;
      this.getCustomerGroup1();
    },
    handlerSearchChangeCC(searching) {
      this.optionDistributionChannel = [];
      this.searchCC = searching;
      this.getCustomerCategory();
    },
    handlerSearchChangeT(searching) {
      this.optionTerritory = [];
      this.searchT = searching;
      this.getTerritory();
    },
    handlerSearchChangeTOP(searching) {
      this.optionTerritory = [];
      this.searchTop = searching;
      this.getPaymenTemp();
    },
    handleFindCustomer() {
      this.getCustomer();
    },
  },
};
</script>
